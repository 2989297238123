$speed: 2s;

html {
  font-size: 20px;
}
html, body {
  height: 100%;
}
@media screen and (max-width:500px) {
  html {
    font-size: 16px;
  }
}
body {
  max-width: 800px;
  padding: 0 20px;
  padding-top: 6rem;
  box-sizing: border-box;
  margin: 0 auto;
  font-family: 'Merriweather', serif;
  line-height: 1.5;
  color: #333;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: url('../img/sky.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0;
  transform: scale(1.2);
  transition: $speed opacity, $speed transform;

  .is-visible & {
    opacity: 1;
    transform: scale(1);
  }
}
.content {
  position: relative;
  z-index: 1;
}

a {
  color: #0019FF;
  text-decoration: none;

  &:hover {
    color: #333;
  }
}
b, strong {
  font-weight: 700;
}
.message {
  margin-top: 3rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem;

  &__meta {
    margin-top: 1rem;
    font-size: .8rem;
    text-align: center;
    opacity: 0;

    .msg-visible & {
      opacity: 1;
      transition: $speed opacity;
    }
  }
  &__text {
    border-top: 4px double #333;
    margin-top: 1rem;
    padding: 1rem;
    opacity: 0;
    line-height: 1.7;

    .msg-visible & {
      opacity: 1;
      transition: $speed opacity;
    }

    p {
      margin: 0;
    }
    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 400px;
      max-width: 100%;
      margin: 0 auto;
    }
    p + p {
      margin-top: 1rem;
    }
    blockquote {
      font-size: .8rem;
    }
  }
}

.headline {
  max-width: 651px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transform: translateY(-40px);
  transition: $speed opacity, $speed transform;

  &__text {
    width: 100%;
    height: auto;
  }
  &__rose {
    position: absolute;
    width: 191px;
    height: auto;
    top: -10px;
    right: -90px;
    transform: translateY(-30px);
    transition: $speed transform;

    .is-visible & {
      transform: translateY(0);
    }

    @media screen and (max-width:500px) {
      right: -25px;
      top: 15px;
      width: 80px;
    }
  }

  .is-visible & {
    transform: translateY(0);
    opacity: 1;
  }
}
.years {
  text-align: center;
  letter-spacing: .05rem;
  color: #0019FF;
  margin-top: 1rem;
  opacity: 0;
  transition: $speed opacity;
  transition-delay: $speed;

  .is-visible & {
    opacity: 1;
  }
}

img.broken {
  width: 40px;
  height: 40px;
}